import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import PreviewCompatibleImage from "../components/image/PreviewCompatibleImage";
import PreviewCompatibleImageAtelier from "../components/image/PreviewCompatibleImageAtelier";
const ProductsPage = ({ data }) => (

  <Layout>
    <div className="content">
      <section className="section section--gradient" style={{ textAlign: 'justify' }}>
        <div className="container">
          <div className="columns has-text-justified">
            <h1>Products</h1>
            <ul>
              {data.allShopifyProduct.edges.map(({ node }) => (
                <li key={node.shopifyId}>
                  <h3>
                    <Link to={`/products/${node.handle}`}>{node.title}</Link>
                    {" - "}${node.priceRangeV2.minVariantPrice.amount}
                  </h3>

                  <div className="column is-6">
                    {node.featuredImage && node.featuredImage.originalSrc &&
                    <PreviewCompatibleImage style={{alignSelf:"center", verticalAlign:'middle'}}
                                            imageInfo={{
                                              image: node.featuredImage.originalSrc,
                                              alt: `Image d'atelier ou d'artistes `,
                                              maxWidth: '200'
                                            }}
                    />
                    }
                  </div>
                  <div className="column is-6">
                    <p>{node.description}</p>

                  </div>
                </li>
              ))}
            </ul>
          </div>

        </div>
      </section>
    </div>
  </Layout>
)
export default ProductsPage

export const query = graphql`
{
  allShopifyProduct(sort: { fields: [title] }) {
  edges {
      node {
        title
        shopifyId
        description
        handle
        priceRangeV2 {
          minVariantPrice {
            amount
          }
        }
        featuredImage {
          altText
          height
          width
          gatsbyImageData
          originalSrc
        }
      }
    }
}
}
`